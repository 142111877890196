import { Typography, Box } from '@mui/material'
import React from 'react'
import { AnimalDto, AnimalProfileDto } from '../../../interactors/gen/backendClient'
import { BrandItem } from '../BrandItem'
import { ProductItem } from '../ProductItem'
import data from '../data/products.json'
import { FelomeAnimalPubCard } from '../../common/FelomeAnimalPubCard'

export interface CategoryProps {
  animalProfile: AnimalProfileDto | AnimalDto
}

export const WellbeingCategory: React.FC<CategoryProps> = ({ animalProfile }) => {
  const species = animalProfile.species
  return (
    <>
      <Typography variant="h2" sx={{ fontWeight: 700, textAlign: 'center' }}>
        Nos services bien-être
      </Typography>
      <BrandItem
        animalProfile={animalProfile}
        title="Assurance Kozoo"
        description={`Remboursement de 50% à 100%\n
        Plafond de 1000€ à 3000€`}
        logoUrl="https://www.kozoo.eu/wp-content/themes/kozoo/assets/images/kozoo-assurance-chien-chat-digitale.svg"
        imgSrc="https://animals-photos.s3.amazonaws.com/2023-04-05+(1).jpg"
        brandName="kozoo"
        catchPhrase="Dès 5,44€/mois"
        redirectUrl="https://bit.ly/4a6FFAJ"
      />
      {species === 'cat' && <FelomeAnimalPubCard animalName={animalProfile.name} />}
      {/* {(species === 'cat' || species === 'dog') && (
        <BrandItem
          animalProfile={animalProfile}
          title="Formation aux premiers secours canins et félins"
          description={`
          Formation en autonomie\n
          3 heures en e-learning`}
          logoUrl="https://i.imgur.com/Mc0XzCF.png"
          imgSrc="https://www.alforme.fr/utils/get_img.php?type=shopproduct&id=6355"
          brandName="alforme"
          catchPhrase={`<span style="text-decoration: line-through">30€</span> 25€`}
        />
      )} */}
      <Typography variant="h2" sx={{ fontWeight: 700, textAlign: 'center', mt: 2 }}>
        Nos produits bien-être
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', gap: 1 }}>
        {data.products
          .filter((product) => product.category.includes('health'))
          .filter((product) => product.species.includes(species) || product.species.length === 0)
          .map((product) => (
            <ProductItem key={product.reference} product={product} animalId={animalProfile.id} />
          ))}
      </Box>
    </>
  )
}
