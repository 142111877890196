import { LoadingButton } from '@mui/lab'
import { Box, Grow, TextField } from '@mui/material'
import dayjs from 'dayjs'
import { debounce } from 'lodash'
import React, { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { getReadableSpecies } from '../../domain/Animal/AnimalDisplay'
import { AnimalProfilesClient } from '../../interactors/clients/AnimalProfilesClient'
import { AnimalDtoSex, AnimalDtoSpecies, AnimalProfileDto } from '../../interactors/gen/backendClient'
import { useAccountsStore } from '../../store/AccountsStore'
import { isValidPastDateCoherence } from '../../utils/date/isValidPastDateCoherence'
import { ControlledDateField } from '../common/ControlledDateField'
import { ControlledSelectField } from '../common/ControlledSelectField'
import { ControlledToggleButton } from '../common/ControlledToggleButton'
import { Field } from '../common/FormField'
import { AddAnimalPhotoModal } from './AddAnimalPhotoModal'

interface FormData {
  name: string
  species: AnimalDtoSpecies
  sex: AnimalDtoSex
  birthday: string
}

type CreateAnimalProfile = Omit<AnimalProfileDto, 'id'>

export const AddAnimalScreen: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [growSpecies, setGrowSpecies] = React.useState(false)
  const [isModalOpened, setIsModalOpened] = React.useState(false)
  const [animal, setAnimal] = React.useState<AnimalProfileDto | null>(null)

  const accountsStore = useAccountsStore()
  const adopterId = accountsStore.connectedAccount?.id ?? ''

  const onSubmit = async (data: FormData) => {
    setIsLoading(true)

    const newAnimalProfile: CreateAnimalProfile = {
      adopterId,
      name: data.name,
      species: data.species,
      sex: data.sex,
      birthday: {
        date: dayjs(data.birthday).toISOString(),
      },
      images: {},
      health: {
        operations: [],
      },
      documents: [],
    }

    const animal = await AnimalProfilesClient.createAnimalProfile(newAnimalProfile)
    setAnimal(animal)
    setIsModalOpened(true)
    setIsLoading(false)
  }

  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      name: '',
      species: undefined,
      sex: undefined,
      birthday: '',
    },
  })

  const debouncedSetGrowSpecies = useCallback(
    debounce(() => setGrowSpecies(true), 500),
    []
  )

  const watchName = watch('name')
  const watchSpecies = watch('species')
  const watchSex = watch('sex')
  const watchBirthday = watch('birthday')

  const growSubmit = watchBirthday != null && watchBirthday !== ''
  const growSex = watchSpecies != null && watchSpecies !== undefined && (watchSpecies as string) !== ''
  const growBirthday = watchSex != null
  return (
    <Box sx={{ padding: { xs: 0, md: 2 }, minHeight: '100%', pb: 20 }}>
      <Box sx={{ maxWidth: '1200px', marginX: 'auto', marginY: 1, p: 4 }}>
        <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
          <Grow in>
            <Box>
              <Field label="Comment s'appelle votre animal ?" required>
                <TextField
                  aria-label="Nom"
                  type="text"
                  placeholder="Médor"
                  {...register('name', { required: 'Le nom est requis' })}
                  onKeyUp={() => debouncedSetGrowSpecies()}
                  error={!!errors.name}
                  helperText={errors.name?.message}
                  required
                  fullWidth
                  hiddenLabel
                />
              </Field>
            </Box>
          </Grow>
          <Grow in={growSpecies}>
            <Box>
              <Field label={`De quel espèce est ${watchName} ?`} required>
                <ControlledSelectField
                  control={control}
                  fieldName="species"
                  error={errors.species}
                  requiredRule="Vous devez définir l'espèce de l'animal"
                  options={Object.values(AnimalDtoSpecies).map((status: AnimalDtoSpecies) => ({
                    label: getReadableSpecies(status, { withEmoji: true }),
                    value: status,
                  }))}
                  size="small"
                />
              </Field>
            </Box>
          </Grow>
          <Grow in={growSex} timeout={500}>
            <Box>
              <Field label="Quel est son sexe ?" required>
                <ControlledToggleButton<FormData>
                  control={control}
                  fieldName="sex"
                  error={errors.sex}
                  requiredRule="Vous devez indiquer le sexe de l'animal"
                  firstOptionLabel="Mâle"
                  secondOptionLabel="Femelle"
                  firstOptionValue={'male'}
                  secondOptionValue={'female'}
                  fullWidth
                />
              </Field>
            </Box>
          </Grow>
          <Grow in={growBirthday} timeout={500}>
            <Box>
              <Field label={`Quand ${watchName} ${watchSex === 'female' ? 'est-elle née' : 'est-il né'} ?`}>
                <ControlledDateField
                  control={control}
                  fieldName="birthday"
                  error={errors.birthday}
                  requiredRule={undefined}
                  validate={(value) => isValidPastDateCoherence(value) || value === null}
                  size="small"
                  label="Date de naissance"
                />
              </Field>
            </Box>
          </Grow>
          <Grow in={growSubmit} timeout={1000}>
            <LoadingButton type="submit" variant="contained" loading={isLoading} sx={{ mt: 2 }}>
              Créer le profil de {watchName}
            </LoadingButton>
          </Grow>
        </form>
      </Box>
      <AddAnimalPhotoModal animal={animal} isModalOpened={isModalOpened} setIsModalOpened={setIsModalOpened} />
    </Box>
  )
}
