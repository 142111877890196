import { ArrowForwardIos } from '@mui/icons-material'
import { Typography, Box } from '@mui/material'
import React from 'react'
import { theme, lightBlue } from '../theme'
import { useNavigate } from 'react-router-dom'

interface Props {
  animalName: string
}

export const FelomeAnimalPubCard: React.FC<Props> = ({ animalName }) => {
  const navigate = useNavigate()
  const handleClick = () => {
    navigate(`/partenaires/felome?animalName=${animalName}`)
  }
  return (
    <>
      <Typography
        variant="h2"
        sx={{
          fontWeight: 700,
          fontSize: '22px',
          textAlign: 'left',
          color: theme.palette.secondary.main,
          mb: 0.5,
          mt: 2,
          width: '100%',
        }}
      >
        Faites son bilan de santé
      </Typography>
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
          backgroundColor: lightBlue,
          borderRadius: '12px',
          overflow: 'hidden',
          width: '100%',
          height: 130,
          transition: 'all 0.3s',
          mb: 2,
          cursor: 'pointer',
          '&:hover': {
            boxShadow: '2px 8px 20px 0 rgba(0,0,0,0.25)',
            '& img': {
              transform: 'scale(1.05)',
            },
          },
          '&::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: '45%',
            zIndex: 1,
            transition: 'opacity 0.3s', // Transition for the opacity
            backgroundImage: `linear-gradient(-80deg, ${lightBlue} 50%, rgba(255, 255, 255, 0) 50.5%)`,
          },
        }}
        onClick={handleClick}
      >
        <Box
          component="img"
          src="https://i.imgur.com/PvfXYmg.jpeg"
          alt="Cat food"
          sx={{
            objectFit: 'cover',
            objectPosition: 'center',
            width: '40%',
            height: '100%', // make image take full height of the container
            zIndex: 0, // Ensure image is behind the pseudo-element
            imageRendering: '-webkit-optimize-contrast',
            transition: 'transform 0.3s ease-in-out', // smooth transition for the scale effect
            mt: -1,
          }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            gap: '4px',
            p: 1,
            pl: 0,
            ml: -3.5,
            zIndex: 2,
          }}
        >
          <Typography variant="h5" component="h2" sx={{ color: 'black', fontWeight: 'bold', fontSize: '16px' }}>
            {' '}
          </Typography>
          <Typography component="p" sx={{ color: 'black', fontSize: '13px', pr: 4, lineHeight: '22px' }}>
            <span style={{ display: 'block', lineHeight: '22px', fontWeight: 700 }}>Dépistage à domicile</span>
            <span style={{ display: 'block', lineHeight: '22px' }}>Les 3 maladies les + fréquentes</span>
            <span style={{ display: 'block', lineHeight: '22px' }}>Résultats en 4 semaines</span>
          </Typography>
          <Box sx={{ height: '19.5px' }}></Box>
          <Box
            sx={{
              position: 'absolute',
              right: 10,
              bottom: 10,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              gap: 1,
              width: '100%',
            }}
          >
            <Typography
              component="p"
              sx={{ color: theme.palette.secondary.dark, fontSize: '14px', fontWeight: 'bold' }}
            >
              Spécial adoption - <span style={{ fontSize: 15 }}>49,99€</span>{' '}
              <span style={{ textDecoration: 'line-through', fontSize: 12 }}>69,99€</span>
            </Typography>
          </Box>
        </Box>
        <ArrowForwardIos
          sx={{ fontSize: '16px', color: theme.palette.secondary.main, position: 'absolute', right: 5 }}
        />
        <Box
          component="img"
          src={'https://www.felome.fr/wp-content/uploads/2023/04/logo-et-nom-compagnie-1.png'}
          alt="Cat food"
          sx={{
            objectFit: 'contain',
            height: '18px', // make image take full height of the container
            zIndex: 2, // Ensure image is behind the pseudo-element
            imageRendering: '-webkit-optimize-contrast',
            position: 'absolute',
            right: 10,
            top: 10,
          }}
        />
      </Box>
    </>
  )
}
