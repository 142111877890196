import React from 'react'
import { CollapsibleBlogArticle } from './Blog/CollapsibleBlogArticle'
import { AnimalDto, AnimalProfileDto } from '../../../interactors/gen/backendClient'
import { Box, Chip, Typography } from '@mui/material'
import { theme } from '../../theme'
import catTips from './Blog/data/catTips.json'
import catDiseases from './Blog/data/catDiseases.json'
import dogTips from './Blog/data/dogTips.json'
import dogDiseases from './Blog/data/dogDiseases.json'
import catObservations from './Blog/data/catObservations.json'
import dogObservations from './Blog/data/dogObservations.json'
import whatIsPetso from './Blog/data/whatIsPetso.json'

interface Props {
  animalProfile: AnimalProfileDto | AnimalDto
}

interface Article {
  title: string
  content: string
}

export const BlogCategory: React.FC<Props> = ({ animalProfile }) => {
  const [selectedCategory, setSelectedCategory] = React.useState<Article[]>(
    animalProfile.species === 'cat' ? catTips : dogTips
  )
  const [selectedStringCategory, setSelectedStringCategory] = React.useState<string>(
    animalProfile.species === 'cat' ? 'catTips' : 'dogTips'
  )

  const handleCategoryChange = (category: string) => {
    switch (category) {
      case 'whatIsPetso':
        setSelectedCategory(whatIsPetso)
        setSelectedStringCategory('whatIsPetso')
        break
      case 'catTips':
        setSelectedCategory(catTips)
        setSelectedStringCategory('catTips')
        break
      case 'catDiseases':
        setSelectedCategory(catDiseases)
        setSelectedStringCategory('catDiseases')
        break
      case 'catObservations':
        setSelectedCategory(catObservations)
        setSelectedStringCategory('catObservations')
        break
      case 'dogTips':
        setSelectedCategory(dogTips)
        setSelectedStringCategory('dogTips')
        break
      case 'dogDiseases':
        setSelectedCategory(dogDiseases)
        setSelectedStringCategory('dogDiseases')
        break
      case 'dogObservations':
        setSelectedCategory(dogObservations)
        setSelectedStringCategory('dogObservations')
        break
      default:
        if (animalProfile.species === 'cat') {
          setSelectedCategory(catTips)
          setSelectedStringCategory('catTips')
        } else {
          setSelectedCategory(dogTips)
          setSelectedStringCategory('dogTips')
        }
    }
  }

  const categoriesToDisplay = () => {
    switch (animalProfile.species) {
      case 'cat':
        return (
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, overflowX: 'auto' }}>
            <Chip
              label="Tips & conseils"
              clickable
              onClick={() => handleCategoryChange('catTips')}
              sx={{
                backgroundColor: selectedStringCategory === 'catTips' ? theme.palette.secondary.main : 'transparent',
                border: `1px solid ${theme.palette.secondary.main}`,
                color: selectedStringCategory === 'catTips' ? 'white' : theme.palette.secondary.main,
                fontSize: '14px',
                px: '12px',
                py: '20px',
                borderRadius: '20px',
                fontWeight: 'bold',
                '&:focus': {
                  backgroundColor: theme.palette.secondary.main,
                  color: 'white',
                },
              }}
            />
            <Chip
              label="Maladies du chat"
              clickable
              onClick={() => handleCategoryChange('catDiseases')}
              sx={{
                backgroundColor:
                  selectedStringCategory === 'catDiseases' ? theme.palette.secondary.main : 'transparent',
                border: `1px solid ${theme.palette.secondary.main}`,
                color: selectedStringCategory === 'catDiseases' ? 'white' : theme.palette.secondary.main,
                fontSize: '14px',
                px: '12px',
                py: '20px',
                borderRadius: '20px',
                fontWeight: 'bold',
                '&:focus': {
                  backgroundColor: theme.palette.secondary.main,
                  color: 'white',
                },
              }}
            />
            <Chip
              label="Observations et symptômes"
              clickable
              onClick={() => handleCategoryChange('catObservations')}
              sx={{
                backgroundColor:
                  selectedStringCategory === 'catObservations' ? theme.palette.secondary.main : 'transparent',
                border: `1px solid ${theme.palette.secondary.main}`,
                color: selectedStringCategory === 'catObservations' ? 'white' : theme.palette.secondary.main,
                fontSize: '14px',
                px: '12px',
                py: '20px',
                borderRadius: '20px',
                fontWeight: 'bold',
                '&:focus': {
                  backgroundColor: theme.palette.secondary.main,
                  color: 'white',
                },
              }}
            />
            <Chip
              label="Petso c'est quoi ?"
              clickable
              onClick={() => handleCategoryChange('whatIsPetso')}
              sx={{
                backgroundColor:
                  selectedStringCategory === 'whatIsPetso' ? theme.palette.secondary.main : 'transparent',
                border: `1px solid ${theme.palette.secondary.main}`,
                color: selectedStringCategory === 'whatIsPetso' ? 'white' : theme.palette.secondary.main,
                fontSize: '14px',
                px: '12px',
                py: '20px',
                borderRadius: '20px',
                fontWeight: 'bold',
                '&:focus': {
                  backgroundColor: theme.palette.secondary.main,
                  color: 'white',
                },
              }}
            />
          </Box>
        )
      case 'dog':
        return (
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, overflowX: 'auto' }}>
            <Chip
              label="Tips & conseils"
              clickable
              onClick={() => handleCategoryChange('dogTips')}
              sx={{
                backgroundColor: selectedStringCategory === 'dogTips' ? theme.palette.secondary.main : 'transparent',
                border: `1px solid ${theme.palette.secondary.main}`,
                color: selectedStringCategory === 'dogTips' ? 'white' : theme.palette.secondary.main,
                fontSize: '14px',
                px: '12px',
                py: '20px',
                borderRadius: '20px',
                fontWeight: 'bold',
                '&:focus': {
                  backgroundColor: theme.palette.secondary.main,
                  color: 'white',
                },
              }}
            />
            <Chip
              label="Maladies du chien"
              clickable
              onClick={() => handleCategoryChange('dogDiseases')}
              sx={{
                backgroundColor:
                  selectedStringCategory === 'dogDiseases' ? theme.palette.secondary.main : 'transparent',
                border: `1px solid ${theme.palette.secondary.main}`,
                color: selectedStringCategory === 'dogDiseases' ? 'white' : theme.palette.secondary.main,
                fontSize: '14px',
                px: '12px',
                py: '20px',
                borderRadius: '20px',
                fontWeight: 'bold',
                '&:focus': {
                  backgroundColor: theme.palette.secondary.main,
                  color: 'white',
                },
              }}
            />
            <Chip
              label="Observations et symptômes"
              clickable
              onClick={() => handleCategoryChange('dogObservations')}
              sx={{
                backgroundColor:
                  selectedStringCategory === 'dogObservations' ? theme.palette.secondary.main : 'transparent',
                border: `1px solid ${theme.palette.secondary.main}`,
                color: selectedStringCategory === 'dogObservations' ? 'white' : theme.palette.secondary.main,
                fontSize: '14px',
                px: '12px',
                py: '20px',
                borderRadius: '20px',
                fontWeight: 'bold',
                '&:focus': {
                  backgroundColor: theme.palette.secondary.main,
                  color: 'white',
                },
              }}
            />
            <Chip
              label="Petso c'est quoi ?"
              clickable
              onClick={() => handleCategoryChange('whatIsPetso')}
              sx={{
                backgroundColor:
                  selectedStringCategory === 'whatIsPetso' ? theme.palette.secondary.main : 'transparent',
                border: `1px solid ${theme.palette.secondary.main}`,
                color: selectedStringCategory === 'whatIsPetso' ? 'white' : theme.palette.secondary.main,
                fontSize: '14px',
                px: '12px',
                py: '20px',
                borderRadius: '20px',
                fontWeight: 'bold',
                '&:focus': {
                  backgroundColor: theme.palette.secondary.main,
                  color: 'white',
                },
              }}
            />
          </Box>
        )
      default:
        return (
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, overflowX: 'auto' }}>
            <Chip
              label="Tips & conseils"
              clickable
              onClick={() => handleCategoryChange('dogTips')}
              sx={{
                backgroundColor: selectedStringCategory === 'dogTips' ? theme.palette.secondary.main : 'transparent',
                border: `1px solid ${theme.palette.secondary.main}`,
                color: selectedStringCategory === 'dogTips' ? 'white' : theme.palette.secondary.main,
                fontSize: '14px',
                px: '12px',
                py: '20px',
                borderRadius: '20px',
                fontWeight: 'bold',
                '&:focus': {
                  backgroundColor: theme.palette.secondary.main,
                  color: 'white',
                },
              }}
            />
            <Chip
              label="Maladies du chien"
              clickable
              onClick={() => handleCategoryChange('dogDiseases')}
              sx={{
                backgroundColor:
                  selectedStringCategory === 'dogDiseases' ? theme.palette.secondary.main : 'transparent',
                border: `1px solid ${theme.palette.secondary.main}`,
                color: selectedStringCategory === 'dogDiseases' ? 'white' : theme.palette.secondary.main,
                fontSize: '14px',
                px: '12px',
                py: '20px',
                borderRadius: '20px',
                fontWeight: 'bold',
                '&:focus': {
                  backgroundColor: theme.palette.secondary.main,
                  color: 'white',
                },
              }}
            />
            <Chip
              label="Petso c'est quoi ?"
              clickable
              onClick={() => handleCategoryChange('whatIsPetso')}
              sx={{
                backgroundColor:
                  selectedStringCategory === 'whatIsPetso' ? theme.palette.secondary.main : 'transparent',
                border: `1px solid ${theme.palette.secondary.main}`,
                color: selectedStringCategory === 'whatIsPetso' ? 'white' : theme.palette.secondary.main,
                fontSize: '14px',
                px: '12px',
                py: '20px',
                borderRadius: '20px',
                fontWeight: 'bold',
                '&:focus': {
                  backgroundColor: theme.palette.secondary.main,
                  color: 'white',
                },
              }}
            />
          </Box>
        )
    }
  }

  return (
    <>
      {categoriesToDisplay()}
      {selectedCategory.map((article, index) => (
        <CollapsibleBlogArticle
          key={index}
          title={article.title}
          content={article.content}
          animalName={animalProfile.name}
        />
      ))}
      <Typography variant="h2" sx={{ mt: 5 }}>
        D&#39;autres articles à venir !
      </Typography>
    </>
  )
}
