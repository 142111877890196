import { Box, Typography } from '@mui/material'
import React from 'react'
import { useParams } from 'react-router-dom'
import { ExpandableCard } from './ExpandableCard'
import { Brands } from './data/Brands'
import { replaceTextInNode } from '../utils/replaceTextInNode'

export const BrandPage: React.FC = () => {
  const { brandName } = useParams()
  const brand = Brands.find((b) => b.name === brandName)
  const queryParams = new URLSearchParams(window.location.search)
  const animalName = queryParams.get('animalName')

  if (!brand) {
    return null
  }
  return (
    <Box sx={{ padding: { xs: 0, md: 2 }, minHeight: '100%', pb: 20 }}>
      <Box
        sx={{
          maxWidth: '1200px',
          marginX: 'auto',
          mb: 10,
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'stretch' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: { xs: 'center', md: 'flex-start' },
              justifyContent: 'space-between',
              gap: '24px',
              p: { xs: 0, md: 2 },
              py: 4,
            }}
          >
            <Box
              component="img"
              src={brand.logoSrc}
              alt={`Logo de ${brand.name}`}
              sx={{
                objectFit: 'contain',
                maxWidth: '100%',
                height: '80px', // make image take full height of the container
                imageRendering: '-webkit-optimize-contrast',
              }}
            />
            <Typography variant="h4" component="h1" sx={{ color: 'black', fontWeight: 'bold', fontSize: '24px' }}>
              {brand.title.replaceAll('votre animal', animalName ?? 'votre animal')}
            </Typography>
          </Box>
          <Box
            component="img"
            src={brand.imgSrc}
            alt={`Illustration de ${brand.name}`}
            sx={{
              objectFit: 'cover',
              maxWidth: '50%',
              height: '260px', // make image take full height of the container
              imageRendering: '-webkit-optimize-contrast',
              display: { xs: 'none', md: 'block' },
              borderRadius: '20px',
            }}
          />
        </Box>
        {replaceTextInNode(brand.description, 'votre animal', animalName ?? 'votre animal')}
        {brand.mainContent}
        {brand.collapses?.map((collapse) => (
          <ExpandableCard key={collapse.title} title={collapse.title}>
            {replaceTextInNode(collapse.content, 'votre animal', animalName ?? 'votre animal')}
          </ExpandableCard>
        ))}
      </Box>
    </Box>
  )
}
