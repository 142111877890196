import { Android, Apple } from '@mui/icons-material'
import { Drawer, Box, Typography, Button } from '@mui/material'
import React from 'react'

interface Props {
  drawerOpen: boolean
  setDrawerOpen: (value: boolean) => void
  setPlatform: (value: 'ios' | 'android') => void
}

export const ChoosePlatformDrawer: React.FC<Props> = ({ drawerOpen, setDrawerOpen, setPlatform }) => {
  return (
    <Drawer anchor={'bottom'} open={drawerOpen} onClose={() => setDrawerOpen(false)}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px', p: 2 }}>
        <Typography variant="h2" component="h2" sx={{ fontSize: '22px', lineHeight: '24.8px', fontWeight: 700 }}>
          Quelle est votre plateforme ?
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            setPlatform('ios')
            setDrawerOpen(false)
          }}
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '2px' }}
        >
          <Apple />
          <Typography>iOS</Typography>
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            setPlatform('android')
            setDrawerOpen(false)
          }}
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '4px' }}
        >
          <Android />
          <Typography>Android</Typography>
        </Button>
      </Box>
    </Drawer>
  )
}
