import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Button, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { z } from 'zod'
import { AdoptionAttemptDto } from '../../interactors/gen/backendClient'
import { UseMutationResult } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { colors, theme } from '../theme'
import { Appearance, loadStripe, StripeElementsOptions } from '@stripe/stripe-js'
import { backendClient } from '../../interactors/clients/client'
import { Elements } from '@stripe/react-stripe-js'
import { CircularProgressPanel } from '../common/CircularProgressPanel'
import { CheckoutForm } from '../common/StripeCheckoutForm'

interface Props {
  updateMutation: UseMutationResult<
    AdoptionAttemptDto,
    unknown,
    {
      adoptionAttempt: AdoptionAttemptDto
      id: string
      noUpdate?: boolean
    },
    unknown
  >
  attempt: AdoptionAttemptDto
  actualName: string
}

const newNameSchema = z.object({
  name: z.string().min(1, 'Le nom est requis'),
})

type NewNameFormData = z.infer<typeof newNameSchema>

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY as string)

export const NewNameAction: React.FC<Props> = ({ updateMutation, attempt, actualName }) => {
  const [step, setStep] = React.useState<number>(1)
  const [clientSecret, setClientSecret] = useState<string>('')
  const navigate = useNavigate()
  const {
    control: nameControl,
    handleSubmit: handleNameSubmit,
    watch,
    formState: { errors: nameErrors },
  } = useForm<NewNameFormData>({
    resolver: zodResolver(newNameSchema),
    defaultValues: { name: actualName },
  })

  const createPaymentIntent = async () => {
    const response = await backendClient.post(
      `/adopters-platform/stripe/create-payment-intent?attemptId=${attempt.id}`,
      {
        items: [{ reference: 'MEDAL', quantity: 1, price: 13.9 * 100 }],
      }
    )
    setClientSecret(response.data)
  }

  const appearance: Appearance = {
    theme: 'stripe',
  }

  const options: StripeElementsOptions = {
    clientSecret,
    appearance,
  }

  const handleFormSubmitName = async (data: NewNameFormData, buyMedal?: boolean) => {
    if (step === 2) {
      const newAttempt: AdoptionAttemptDto = { ...attempt, petHasNewName: true, newName: data.name }
      await updateMutation.mutateAsync({ adoptionAttempt: newAttempt, id: attempt.id, noUpdate: buyMedal })
      if (buyMedal) {
        await createPaymentIntent()
        return setStep(3)
      } else {
        navigate(`/adoptions/${attempt.id}`)
      }
    }
    setStep(2)
  }

  const PaymentStep = () => {
    if (!clientSecret) {
      return <CircularProgressPanel absolute={false} />
    }
    return (
      <Box
        sx={{
          py: 4,
        }}
      >
        <Box
          sx={{
            backgroundColor: colors.blueSky,
            color: 'white',
            fontSize: '22px',
            fontWeight: 700,
            textAlign: 'center',
            width: 'fit-content',
            padding: '8px',
            borderRadius: '11px',
            transform: 'rotate(-1deg)',
            mb: 4,
          }}
        >
          Paiement par carte bancaire
        </Box>
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm
            price={13.9}
            redirectionLink={`https://adoptant.petso.fr/adoptions/${attempt.id}`}
            buttonFontSize="18px"
          />
        </Elements>
      </Box>
    )
  }

  const animalName = watch('name')
  return step === 1 ? (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, p: 4 }}>
      <Typography variant="h3" fontSize={26}>
        Alors, à qui doit-on souhaiter la bienvenue ?
      </Typography>
      <form
        onSubmit={handleNameSubmit((data) => handleFormSubmitName(data, false))}
        style={{ width: '100%', maxWidth: '460px' }}
      >
        <Controller
          name="name"
          control={nameControl}
          render={({ field }) => (
            <TextField
              {...field}
              label="Nom"
              fullWidth
              margin="normal"
              error={!!nameErrors.name}
              helperText={nameErrors.name ? nameErrors.name.message : ''}
            />
          )}
        />
      </form>

      <Button
        onClick={handleNameSubmit((data) => handleFormSubmitName(data, false))}
        variant="contained"
        color="primary"
        fullWidth
        sx={{ fontSize: '18px' }}
      >
        Rebaptiser
      </Button>

      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', width: '100%', gap: 2, mt: 4 }}>
        <Box
          sx={{
            backgroundColor: colors.blueSky,
            color: 'white',
            fontSize: '22px',
            fontWeight: 700,
            width: 'fit-content',
            padding: '8px',
            pr: '50px',
            borderRadius: '11px',
            transform: 'rotate(-1deg)',
          }}
        >
          Bon à savoir
        </Box>
        <Typography variant="body1" sx={{ textAlign: 'left' }}>
          {`Cette année c'est la lettre "V" qui est à l'honneur pour les noms d'animaux. `}
        </Typography>
      </Box>
    </Box>
  ) : step === 2 ? (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
        p: 4,
        px: 2,
        width: '100%', // Ensures it takes up the full width
        height: '100%', // Ensures it takes up the full height
        maxHeight: '100%', // Prevents overflow
        margin: 0, // Removes any default margin
        position: 'fixed', // Allows absolute positioning inside
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 9999,
        backgroundColor: 'white',
      }}
    >
      {/* Header Box */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 0,
          mb: 2,
        }}
      >
        <span style={{ fontSize: '72px' }}>🎉</span>
        {/* <Typography variant="h5" sx={{ color: 'black', fontWeight: 700, textAlign: 'center', fontSize: 22 }}>
          Enchanté {animalName} !
        </Typography> */}
        <Box
          sx={{
            backgroundColor: colors.blueSky,
            color: 'white',
            fontSize: '22px',
            fontWeight: 700,
            textAlign: 'center',
            width: 'fit-content',
            padding: '8px',
            borderRadius: '11px',
            transform: 'rotate(-1deg)',
          }}
        >
          Vive {animalName} !
        </Box>
      </Box>

      {/* Text Content */}
      <Box
        sx={{
          width: 185,
          height: 200,
          borderRadius: '11px',
          overflow: 'hidden',
          position: 'relative',
          backgroundImage: `url(https://i.imgur.com/npl5Mde.jpeg)`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          filter: 'saturate(1.2)', // Darkens and enhances colors for text contrast
        }}
      >
        {/* Animal Name Placeholder Overlay */}
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translateX(-50%) translateY(-50%)',
            width: '90%',
            textAlign: 'center',
            borderRadius: '5px',
            p: 0.5,
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{
              color: 'darkgrey',
              fontWeight: 'bold',
              fontSize: 22,
              textAlign: 'center',
            }}
          >
            {animalName}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Typography
          variant="body1"
          sx={{ textAlign: 'center', fontWeight: 700, color: theme.palette.secondary.main, fontSize: 19 }}
        >
          Sa médaille est prête !
        </Typography>
        <Typography variant="body1" sx={{ mb: 2, textAlign: 'center', fontSize: 17, px: 2 }}>
          Votre numéro est gravé au dos.
          <br />- 06 06 06 06 06 -
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Button
          onClick={handleNameSubmit((data) => handleFormSubmitName(data, true))}
          variant="contained"
          color="primary"
          fullWidth
          sx={{ fontSize: '18px' }}
        >
          Je commande (13,90€)
        </Button>
        <Button
          variant="text"
          onClick={handleNameSubmit((data) => handleFormSubmitName(data, false))}
          sx={{
            borderColor: '#c5d2de',
            color: '#6b7990',
            textTransform: 'none',
            my: 1,
            width: '100%',
            fontSize: '18px',
            textDecoration: 'underline',
            ':hover': {
              backgroundColor: '#eef2f7',
            },
          }}
        >
          Je ne veux pas la médaille
        </Button>
      </Box>
    </Box>
  ) : (
    <PaymentStep />
  )
}
