import React from 'react'
import { Box, SxProps, Typography } from '@mui/material'
import { metricalpEvent } from '@metricalp/react'
import { useAccountsStore } from '../../store/AccountsStore'

interface Props {
  backgroundColor?: string
  title?: string
  imgSrc: string
  slug: string
  selectACategory: (category: string) => void
  halfSize?: boolean
  disabled?: boolean
  sx?: SxProps
}

export const getContrastColor = (backgroundColor: string) => {
  // This is a very simplified way to determine contrast color.
  // It assumes if the background color is light (e.g., starts with '#fff', 'white', or a light color code), text color will be black, otherwise white.
  // For a more accurate contrast calculation, you may need a more complex function that calculates luminance and contrast ratio.
  if (
    backgroundColor.startsWith('#fff') ||
    backgroundColor.startsWith('white') ||
    parseInt(backgroundColor.replace('#', ''), 16) > 0xffffff / 2
  ) {
    return 'black'
  }
  return 'white'
}

// Function to darken the background color on hover
export const darkenColor = (color: string) => {
  const factor = 0.8 // determines how much darker the color should be
  const hex = color.replace('#', '')
  const r = Math.floor(factor * parseInt(hex.substring(0, 2), 16))
  const g = Math.floor(factor * parseInt(hex.substring(2, 4), 16))
  const b = Math.floor(factor * parseInt(hex.substring(4, 6), 16))
  return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`
}

export const CategoryItem: React.FC<Props> = ({
  backgroundColor = '#d9a877',
  title = 'Croquettes pour chat',
  imgSrc,
  slug,
  halfSize = false,
  disabled = false,
  selectACategory,
  sx,
}) => {
  const textColor = getContrastColor(backgroundColor)

  const accountsStore = useAccountsStore()
  const account = accountsStore.connectedAccount

  const handleClick = () => {
    if (disabled) return
    metricalpEvent({
      type: `click_category_${title.toLowerCase().replace(' ', '_')}`,
      adopterId: account?.id,
      adopterName: `${account?.firstName} ${account?.lastName}`,
    })
    selectACategory(slug)
  }
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        backgroundColor: !disabled ? backgroundColor : darkenColor(backgroundColor),
        borderRadius: '12px',
        overflow: 'hidden',
        width: { md: '48%', xs: halfSize ? '47%' : '100%' },
        height: 120,
        transition: 'all 0.3s',
        cursor: !disabled ? 'pointer' : 'not-allowed',
        '&:hover': !disabled
          ? {
              boxShadow: '0 4px 20px 0 rgba(0,0,0,0.25)',
              backgroundColor: darkenColor(backgroundColor), // Darken the background color on hover
              '&::after': {
                opacity: 0, // Hide the original gradient
              },
              '&::before': {
                opacity: 1, // Show the new gradient
              },
              '& img': {
                transform: 'scale(1.05)',
              },
            }
          : {},
        '&::before, &::after': {
          content: '""',
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: '20%',
          zIndex: 1,
          transition: 'opacity 0.3s', // Transition for the opacity
        },
        '&::after': {
          backgroundImage: `linear-gradient(100deg, ${
            !disabled ? backgroundColor : darkenColor(backgroundColor)
          } 50%, rgba(255, 255, 255, 0) 50.5%)`,
        },
        '&::before': {
          backgroundImage: `linear-gradient(100deg, ${darkenColor(backgroundColor)} 50%, rgba(255, 255, 255, 0) 50.5%)`,
          opacity: 1, // Start fully transparent
        },
        ...sx,
      }}
      onClick={() => handleClick()}
    >
      <Box sx={{ p: 2, zIndex: 2, maxWidth: '55%' }}>
        <Typography variant="h5" component="h1" sx={{ color: textColor, fontWeight: 'bold' }}>
          {title}
        </Typography>
      </Box>
      <Box
        component="img"
        src={imgSrc}
        alt="Cat food"
        sx={{
          position: 'absolute',
          right: 0,
          objectFit: 'cover',
          width: '45%',
          height: '100%', // make image take full height of the container
          zIndex: 0, // Ensure image is behind the pseudo-element
          imageRendering: '-webkit-optimize-contrast',
          transition: 'transform 0.3s ease-in-out', // smooth transition for the scale effect
        }}
      />
      {disabled && (
        <Box
          sx={{
            position: 'absolute',
            bottom: '50%',
            left: '50%',
            transform: 'rotate(-15deg) translateX(-50%) translateY(-50%)',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            zIndex: 20,
          }}
        >
          <Typography
            sx={{
              fontSize: '20px',
              fontWeight: '600',
              color: 'black',
              whiteSpace: 'nowrap',
            }}
          >
            Prochainement !
          </Typography>
        </Box>
      )}
    </Box>
  )
}
