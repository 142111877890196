import React, { useState } from 'react'
import { AdoptionAttemptDto } from '../../interactors/gen/backendClient'
import { Box, IconButton, Typography } from '@mui/material'
import { colors } from '../theme'
import { LoadingButton } from '@mui/lab'
import { ChoosePlatformDrawer } from '../utils/ChoosePlatformDrawer'
import { ArrowDownward, Close, IosShare, MoreVert } from '@mui/icons-material'
import { AdoptersClient } from '../../interactors/clients/AdoptersClient'

interface Props {
  attempt: AdoptionAttemptDto
  fullySetAdoptionAttempt: (attempt: AdoptionAttemptDto) => void
}

export const DownloadAppCard: React.FC<Props> = ({ attempt, fullySetAdoptionAttempt }) => {
  const [loading, setLoading] = useState(false)
  const [platform, setPlatform] = useState<'android' | 'ios'>()
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [done, setDone] = useState(false)
  const [closed, setClosed] = useState(false)

  const sx = {
    mt: 4,
  }

  const onSubmit = async () => {
    setLoading(true)
    const updatedAttempt = await AdoptersClient.updateAdoptionAttemptDummyInfos({
      id: attempt.id,
      adoptionAttempt: { ...attempt, hasSeenTutorialToInstallApp: true },
    })
    fullySetAdoptionAttempt(updatedAttempt)
    setDone(true)
    setLoading(false)
  }

  const onNotInterested = async () => {
    setLoading(true)
    const updatedAttempt = await AdoptersClient.updateAdoptionAttemptDummyInfos({
      id: attempt.id,
      adoptionAttempt: { ...attempt, hasSeenTutorialToInstallApp: true },
    })
    fullySetAdoptionAttempt(updatedAttempt)
    setLoading(false)
  }

  const displaySelectedPlatformInfo = () => {
    if (platform === 'android') {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center', ...sx }}>
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <Box
              sx={{
                backgroundColor: colors.blueSky,
                color: 'white',
                fontSize: '22px',
                fontWeight: 700,
                width: 'fit-content',
                padding: '2px',
                pl: '8px',
                pr: '50px',
                borderRadius: '11px',
                transform: 'rotate(-1deg)',
              }}
            >
              Enregistrer l&apos;application 📲
            </Box>
          </Box>
          <Typography variant="body1" sx={{ textAlign: 'center', maxWidth: 400, px: 4 }}>
            Cliquez sur l&apos;îcone <MoreVert /> en haut à droite de votre navigateur et sélectionnez “installer
            l&apos;application”
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', maxWidth: 360, mt: 2 }}>
            <LoadingButton
              variant="contained"
              color="primary"
              fullWidth
              sx={{ fontSize: '18px', mb: 1 }}
              onClick={() => onSubmit()}
              loading={loading}
            >
              C&apos;est fait
            </LoadingButton>
            <LoadingButton
              variant="text"
              sx={{
                color: '#6b7990',
                textTransform: 'none',
                fontSize: '18px',
                textDecoration: 'underline',
                ':hover': {
                  backgroundColor: '#eef2f7',
                },
              }}
              onClick={() => window.open('https://www.youtube.com/watch?v=LJj-EMeBC1g', '_blank')}
              loading={loading}
            >
              Je ne trouve pas
            </LoadingButton>
          </Box>
        </Box>
      )
    } else if (platform === 'ios') {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center', ...sx }}>
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <Box
              sx={{
                backgroundColor: colors.blueSky,
                color: 'white',
                fontSize: '22px',
                fontWeight: 700,
                width: 'fit-content',
                padding: '2px',
                pl: '8px',
                pr: '50px',
                borderRadius: '11px',
                transform: 'rotate(-1deg)',
              }}
            >
              Enregistrer l&apos;application 📲
            </Box>
          </Box>
          <Typography variant="body1" sx={{ textAlign: 'center', maxWidth: 400, px: 4 }}>
            Cliquez sur l’icone <IosShare /> de votre navigateur et sélectionnez “sur l’écran d’accueil“
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', maxWidth: 360, mt: 2 }}>
            <LoadingButton
              variant="contained"
              color="primary"
              fullWidth
              sx={{ fontSize: '18px', mb: 1 }}
              onClick={() => onSubmit()}
              loading={loading}
            >
              C&apos;est fait
            </LoadingButton>
            <LoadingButton
              variant="text"
              sx={{
                color: '#6b7990',
                textTransform: 'none',
                fontSize: '18px',
                textDecoration: 'underline',
                ':hover': {
                  backgroundColor: '#eef2f7',
                },
              }}
              onClick={() => window.open('https://www.youtube.com/watch?v=iMSXKaHuMqE', '_blank')}
              loading={loading}
            >
              Je ne trouve pas
            </LoadingButton>
          </Box>
          <ArrowDownward sx={{ color: colors.blueSky, fontSize: 40 }} />
        </Box>
      )
    }
    return null
  }

  return closed ? null : done ? (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        alignItems: 'center',
        mt: 4,
        p: 3,
        border: '1px solid #ddd',
        borderRadius: 2,
        maxWidth: 400,
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
      }}
    >
      <IconButton
        onClick={() => setClosed(true)}
        sx={{
          position: 'absolute',
          top: 8,
          right: 8,
          color: 'grey.600',
        }}
        aria-label="close"
      >
        <Close />
      </IconButton>
      <Typography variant="body1" sx={{ textAlign: 'center' }}>
        <b>Application installée 🎉</b>
        <br />
        Quittez cette page et rendez-vous sur l’application
        <br />
        <br />
        <b>Et pour nous soutenir...</b>
      </Typography>
      <LoadingButton
        variant="contained"
        color="primary"
        fullWidth
        sx={{ fontSize: '18px', mb: 1 }}
        onClick={() => window.open('https://instagram.com/petso.fr', '_blank')}
        loading={loading}
      >
        Suivez-nous sur Instagram
      </LoadingButton>
    </Box>
  ) : attempt.hasSeenTutorialToInstallApp ? null : platform ? (
    displaySelectedPlatformInfo()
  ) : (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center', ...sx }}>
      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <Box
          sx={{
            backgroundColor: colors.blueSky,
            color: 'white',
            fontSize: '22px',
            fontWeight: 700,
            width: 'fit-content',
            padding: '2px',
            pl: '8px',
            pr: '50px',
            borderRadius: '11px',
            transform: 'rotate(-1deg)',
          }}
        >
          Restez alerte ! 💡
        </Box>
      </Box>
      <Typography variant="body1" sx={{ textAlign: 'center', fontSize: 42 }}>
        📲
      </Typography>
      <Typography variant="body1" sx={{ textAlign: 'center', maxWidth: 400 }}>
        Télécharger l’application pour être notifié des prochaines étapes d’adoption
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', maxWidth: 360, mt: 2 }}>
        <LoadingButton
          variant="contained"
          color="primary"
          fullWidth
          sx={{ fontSize: '18px', mb: 1 }}
          onClick={() => setDrawerOpen(true)}
          loading={loading}
        >
          Enregistrer l&apos;application
        </LoadingButton>
        <LoadingButton
          variant="text"
          sx={{
            color: '#6b7990',
            textTransform: 'none',
            fontSize: '18px',
            textDecoration: 'underline',
            ':hover': {
              backgroundColor: '#eef2f7',
            },
          }}
          onClick={() => onNotInterested()}
          loading={loading}
        >
          Ne pas enregistrer
        </LoadingButton>
      </Box>
      <ChoosePlatformDrawer drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} setPlatform={setPlatform} />
    </Box>
  )
}
