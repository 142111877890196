export const scrollToSection = (sectionId: string) => {
  const scrollContainer = document.querySelector('.scrollable-content') as HTMLElement
  const targetSection = document.getElementById(sectionId)

  console.log(targetSection)

  if (scrollContainer && targetSection) {
    targetSection.scrollIntoView({
      behavior: 'smooth', // Smooth scroll animation
      block: 'start', // Align the section to the top of the container
      inline: 'nearest', // If horizontal scrolling is needed
    })
  }
}
